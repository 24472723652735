<template>
  <div>
    <a-modal :visible="visible" :title="title" @ok="handok" @cancel="cancel" width="40%">
      <div class="flex wrap">
        <div v-for="item in labelList" :key="item.key" style="width:49%;">
          <span>{{ item.label }}: {{ item.value }} </span>
        </div>
      </div>
      <div class="m-t1">
        <span><span style="color: red;font-family: SimSun, sans-serif;">*</span>原因：</span>
        <a-textarea v-model:value="invoiceValue" placeholder="" :rows="4" />
      </div>
      <div class="m-t1">
        <a-upload @preview="previewFile" v-model:file-list="imgFileList" accept="image/*" list-type="picture" :remove="removeChange" :before-upload="beforeUpload">
          <a-button>
            <CameraOutlined />
            图片
          </a-button>
        </a-upload>
      </div>
    </a-modal>
    <!-- 图片预览 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { CameraOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue';
import { fileUpload as upload } from '@/api/marketing/modList'
const props = defineProps({
  cancelInfo: {
    type: Object,
    default: () => ({})
  },
  visible: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:visible', 'handok'])
const previewVisible = ref(false)
const previewImage = ref('')
const title = ref('')
const isUpdateLoading = ref(false)
const imgFileList = ref([])
const fileListInfo = ref([])
const invoiceValue = ref('')
const labelList = ref([
  {
    label: '发票号',
    key: 'invoiceNum',
    value: ''
  },
  {
    label: '金额',
    key: 'invoiceAmt',
    value: ''
  },
])
const cancel = () => {
  emit('update:visible', false)
}
// 图片预览
const previewFile = async (file) => {
  if (!file.url && !file.preview) {
    file.preview = (await getBase64(file.originFileObj))
  }
  previewImage.value = file.url || file.preview
  previewVisible.value = true
}
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
// 删除图片
const removeChange = (file) => {
  fileListInfo.value.forEach((item, index) => {
    if (item.name === file.originFileObj.name) fileListInfo.value.splice(index, 1)
  })
}
const handok = () => {
  if(isUpdateLoading.value) return
  let applyFileIds = []
  fileListInfo.value.forEach(item => {
    applyFileIds.push(item.id)
  })
  let msg = {
    applyFileIds: applyFileIds,
    remark: invoiceValue.value
  }
  if(!msg.remark) {
    message.warn('请填写原因')
    return 
  }
  emit('handok', msg)
}
const beforeUpload = (file) => {
  updateBatch(file)
  return false;
}
defineExpose({
  invoiceValue,
  fileListInfo
})
const key = 'updatable';
const updateBatch = (file) => {
  isUpdateLoading.value = true
  message.loading({ content: '上传中..', key });
  const formData = new FormData()
  formData.append('file', file)
  upload(formData).then((res) => {
    isUpdateLoading.value = false
    if (res.code === 10000) {
      fileListInfo.value.push(res.data)
      message.success({ content: '上传成功', key, duration: 2 });
    } else {
      fileList.value = []
      fileListInfo.value = []
      message.error({ content: '上传失败', key, duration: 2 });
    }
  })
}
watch(() => props.visible, (val, oldVal) => {
  if (val) {
    let str = props.cancelInfo.applyInvoiceType?.value == 2?'作废申请':'红冲申请'
    title.value =   props.cancelInfo.frontInvoiceApplyId + '—' + str
    labelList.value.forEach(item => {
      if(item.obj){
        item.value = props.cancelInfo[item.key][item.obj]
      }else{
        item.value = props.cancelInfo[item.key]
      }
    })
  }else{
   imgFileList.value = []
   fileListInfo.value = []
   invoiceValue.value = ''
  }
})

</script>

<style lang="less" scoped>
</style>