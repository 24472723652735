<template>
  <div>
    <a-modal :visible="visible" title="开票" width="85%" @cancel="cancel" :centered="true"  :footer="null">
      <div class="flex ju-center m-t2">
        <div style=" width: 40%;" class="flex al-center">
          <a-select v-model:value="customerId" placeholder="请输入" style="width: 100%" :filter-option="false" :show-arrow="false" size="large"  @search="carrierAutoComplete" show-search>
            <a-select-option v-for="item in carriers" :key="item.id">
              <span>{{ item.name }}-{{item.mobile}}</span>
              <span style="float:right;">{{ item.taxpayerIdentificationNum }}</span>
            </a-select-option>
          </a-select>
          <a-button class="m-l1" style="height: 40px;width: 100px;" @click="searchAdd" type="primary">搜索</a-button>
          <a-button class="m-l1" style="height: 40px;;width: 150px;" @click="submit" danger>保存开票</a-button>
        </div>
      </div>
      <div class="contentBox">
        <div class="tips">
          <span>已选择<span style="margin: 0 5px;color:red">{{ state.selectedRowKeys.length }}</span> 辆</span>
          <span class="m-l2">合计开票金额：<span style="color:red">￥{{ state.calculatedAmount.toFixed(2) }}</span></span>
          <a class="m-l4" @click="state.selectedRowKeys=[],state.calculatedAmount=0">清空选择</a>
        </div>
        <div>
          <SearchList :iptData="iptData" @searchAdd="getVal" @resetBtn="resetBtn">
            <template #customFront>
          <a-select
           style="margin-right:10px"
            allowClear
            v-model:value="state.invoiceCarrier.value"
            :style="{ width: state.invoiceCarrier.width + 'px'}"
            :placeholder="state.invoiceCarrier.placeholder"
            @select="selectChnage"
          >
          <a-select-option   v-for="opt in state.invoiceCarrier.opt" :key="opt.id" :value="opt.id">{{ opt.subjectName }}</a-select-option>
          </a-select>
          <a-select
            style="margin-right:10px"
            allowClear
            v-model:value="state.taxItem.value"
            :style="{ width: state.taxItem.width + 'px'}"
            :placeholder="state.taxItem.placeholder"
            :disabled="state.invoiceCarrier.value?false:true"
          >
          <a-select-option v-for="opt in state.taxItem.opt" :key="opt.id" :value="opt.id">{{ opt.label }}</a-select-option>
          </a-select>
        </template>
          </SearchList>
        </div>
        <div>
          <a-table  ref="myTable"   :scroll="{ x:0,y:450  }" :rowKey="(record, index) => { return index;}"  :loading="loading"  size="small"   bordered :pagination="false" :row-selection="{ selectedRowKeys:state.selectedRowKeys, onChange: onSelectChange }" :columns="columns" :data-source="dataList">
            <template #vinNo="{record }">
             {{  record.vinNo }}  {{ record.brand }} {{ record.model }}
            </template>
            <template #settlementStatus="{ record  }">
                {{ record.settlementStatus.label }}
            </template>
            <template #taxRate="{ record }">
              <div v-if="record.taxRate">
                 {{ record.taxRate }} %
              </div>
            </template>
            <template #invoiceAmt="{ record }">
              <div v-if="record.invoiceAmt">
                {{ record.invoiceAmt  }}
              </div>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive,watch, onMounted, nextTick ,onBeforeUnmount} from 'vue'
import SearchList from '@/components/searchList/index.vue';
import { fuzzyAll } from '@/api/crm/carrer/carrier'
import { debounce } from "@/utils/util.js";
import { vehicleInvoice,billInvoiceAdd } from '@/api/finance/finance.js';
import { getSubjectList } from '@/api/trans/order'
import { message } from 'ant-design-vue';
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  invoiceCarrierList:{
    type:Array,
    default:()=>[]
  }
})
const state = reactive({
  selectedRowKeys: [],
  timer: null,
  content: '',
  calculatedAmount:0,
  invoiceCarrier:{
    value: null,
    width: 220,
    placeholder: '开票主体',
    opt:[]
  },
  taxItem:{
    value: null,
    width: 220,
    placeholder: '开票税率',
    opt:[]
  },
})

const pagination = ref({
  showSizeChanger: true,
  showQuickJumper: true,
  current: 1,
  pageSize: 20,
  // total: 0,
  // showTotal: (totals) => `共 ${totals} 条`
})


const changePage = (val) => {
  pagination.value = val
  searchAdd()
}
const isSuccess = ref(false)
const loading = ref(false)
const customerId = ref(null)
const carriers = ref([])
const dataList = ref([])
// 重置
const resetBtn = () => {
  state.invoiceCarrier.value = null
  state.taxItem.value = null
  searchMsg.value = {}
  searchAdd()
}
// 筛选
const searchMsg = ref({})
const getVal = (info) => {
  let subject = {
    invoiceCarrier:processingData(state.invoiceCarrier.opt,state.invoiceCarrier.value,'subjectName'),
    taxItem:processingData(state.taxItem.opt,state.taxItem.value,'taxItem'),
    taxRate:processingData(state.taxItem.opt,state.taxItem.value,'taxRate'),
  }
  let val = JSON.parse(JSON.stringify(info))
  val.startAddress  = processingAddress(val.startAddress)
  val.endAddress  = processingAddress(val.endAddress)
  Object.assign(val,subject)
  searchMsg.value = val
  searchAdd()
}
//处理数据
const processingAddress = (data) => {
  if(!data) return null
   let msg = {
    province:data[0],
    city:data[1],
    area:data[2]
   }
   return msg
}
const processingData = (data,id,label) => {
  let str = ''
  data.forEach(item => {
    if(item.id == id) {
      str = item[label]
    }
  })
  return str
}

const emit = defineEmits(['update:visible','submit'])
const selectChnage = (item) => {
   reqGetSubjectList(item)
   state.taxItem.value = null
 }
//保存发票
const submit = () => {
  if(isSuccess.value) return
  if(state.selectedRowKeys.length == 0){
    message.warn('请选择车辆')
    return
  }

 let list = []
 state.selectedRowKeys.map(key =>{
   list.push(dataList.value[key])
 })
 let applyDetails = []
 if(!list.every(item => item.invoiceCarrier ==  list[0].invoiceCarrier)){
    message.warn('开票主体不一致，请从新选择')
    return
  }
  if(!list.every(item => item.taxRate ==  list[0].taxRate)){
    message.warn('开票税率不一致，请从新选择')
    return
  }
  isSuccess.value = true
list.map( item => {
    applyDetails.push({
    applyAmt:item.invoiceAmt,
    orderVehicleInvoiceId:item.orderVehicleInvoiceId
    })
 })
 let obj = {
  amt: (state.calculatedAmount).toFixed(2),
  applyDetails:applyDetails,
  customerId:customerId.value,
  customerName:funIsID(carriers.value,'id',customerId.value,'name')
 }
 billInvoiceAdd(obj).then( res => {
  if(res.code !== 10000) return
   message.success(res.msg)
  cancel()
  emit('submit')
 }).finally(() => {
  isSuccess.value = false
 })

}
const funIsID = (arr,type,info,name) => {
     let str = ''
     arr.forEach( item => {
      if(item[type] == info){
        str = item[name]
      }
     })
     return str
}
//获取主体列表
const reqGetSubjectList = (id,name) => {
      getSubjectList(id).then( res => {
        if(res.code !== 10000) return
        if(id == 0){
          state.invoiceCarrier.opt = res.data
        }else{
          res.data.forEach( item => {
          item.label = item.subjectName + `【${item.taxItem}】`
          })
          state.taxItem.opt = res.data
        }
      })
    }
// 搜索

const searchAdd = () => {
  if(!customerId.value) return
  loading.value = true
   let msg = {
    customerId:customerId.value,
    current:pagination.value.current,
    size:pagination.value.pageSize
   }
   Object.assign(msg,searchMsg.value)
  vehicleInvoice(msg).then( res => {
    loading.value = false
    state.selectedRowKeys = []
    state.calculatedAmount = 0
    dataList.value = res.data
   
  })

}

const calculatedAmount = (arr,type) => {
  if(!arr) return ''
  let num = 0
  arr.forEach( item => {
    num += Number(item[type])
  })
  return num
}

const carrierAutoComplete = val => {
  state.content = val
  debounce(state, reqFuzzyAll, 1000)
}
const reqFuzzyAll = () => {
  fuzzyAll({ name: state.content, type: 1 }).then(res => {
    if (res.code === 10000) {
      carriers.value = res.data
    } else {
      carriers.value = []
    }
  })
}
const onSelectChange = (selectedRowKeys,e) => {
  state.calculatedAmount = calculatedAmount(e,'invoiceAmt')
  state.selectedRowKeys = selectedRowKeys;
};
const cancel = () => {
  emit('update:visible', false)
}

const render = (num) => {
 console.log(num);
}
const myTable = ref(null)
const handleScroll = () => {
  const tableComponent = myTable.value;
     const tableContainer = tableComponent.$el.querySelector('.ant-table-body');
     const scrollPosition = tableContainer.scrollTop;
     const isAtTop = scrollPosition === 0;
     if (isAtTop) {
        // 已滚动到顶部，执行相应操作
       render(-100)
     }
     console.log(scrollPosition);
     const isAtBottom = tableContainer.scrollHeight - scrollPosition === tableContainer.clientHeight;

     if (isAtBottom) {
       // 已滚动到底部，执行相应操作
       render(100)
     }
}

onMounted (() => {
  iptData.value[1].opt = store.state.app.cityDict
  iptData.value[2].opt = store.state.app.cityDict
  state.invoiceCarrier.opt = props.invoiceCarrierList
  nextTick(() => {
    const tableComponent = myTable.value
    if (tableComponent) {
      const tableContainer = tableComponent.$el.querySelector('.ant-table-body');
      tableContainer.addEventListener('scroll', handleScroll);
    }
  })
})
onBeforeUnmount(() => {
  nextTick(() => {
     const tableComponent = myTable.value
     if (tableComponent) {
       const tableContainer = tableComponent.$el.querySelector('.ant-table-body');
       tableContainer.removeEventListener('scroll', handleScroll);
     }
   })
});
const columns = [
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    key:'vinNo',
    align: 'center',
    slots:{ customRender:'vinNo' }
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    key:'customerName',
    align: 'center',
    width: "100px"
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    key:'orderId',
    align: 'center',
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus.label',
    key:'orderStatus',
    align: 'center',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    key: 'pushTime',
    align: 'center'
  },
  {
    title: '起始地',
    dataIndex: 'startAddress',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    key:'orderId',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    key:'salesman',
    align: 'center',
    width:80
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    key:'orgName',
    align: 'center',
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus',
    key:'settlementStatus',
    align: 'center',
    slots:{ customRender:'settlementStatus' }
  },
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    key:'invoiceCarrier',
    align: 'center',
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    key:'taxRate',
    align: 'center',
    slots:{ customRender:'taxRate' },
    width:80
  },
  {
    title: '税目',
    dataIndex: 'taxItem',
    key:'taxItem',
    align: 'center',
  },
  {
    title: '开票金额',
    dataIndex: 'invoiceAmt',
    key:'invoiceAmt',
    align: 'center',
    slots:{ customRender:'invoiceAmt' }
  },
];

const iptData = ref([
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 150,
  },
  {
    type: "cascader",
    placeholder: "起始地",
    value: null,
    prop: "startAddress",
    changeOn:true,
    opt:[],
    width: 150,
  },
  {
    type: "cascader",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    opt:[],
    changeOn:true,
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "结算状态",
    value: null,
    prop: "settlementStatus",
    width: 120,
    opt:[
      { name: '未结算', id: 0 },
        { name: '部分结算', id: 1 },
        { name: '已结算', id: 2 }
    ]
  },

]);
</script>

<style lang="less" scoped>
.contentBox {
  margin-top: 60px;
  .tips {
    height: 40px;
  }
}
:deep(.navBox) {
  padding: 0;
}
</style>
