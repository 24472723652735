<template>
<div>
  <FormModal width="32%" @getVal="getVal" ref="modalRef" :visible="visible" @cancel="cancel" title="新增发票抬头"  :fromList="fromList">
      <template #Header>
        <div>
          <a-form >
            <a-form-item label="" name="headerTypeId">
             <div style="margin-bottom:6px"><span style="color:red;font-family:SimSun, sans-serif">*</span> 抬头类型</div>
              <a-select v-model:value="headerTypeId" placeholder="请选择" @change="headerTypeChange">
                <a-select-option value="1">个人</a-select-option>
                <a-select-option value="2">政府单位</a-select-option>
                <a-select-option value="3">企业</a-select-option>
              </a-select>
            </a-form-item>
          </a-form> 
        </div>
      </template>
      <template #custom>
        <div>
          <div v-if="headerTypeId==3">
            <span>一般纳税人资格证照片<span style="color:red">（如需开专票必须上传资格证照片）</span></span>
            <div>
                <a-upload  @preview="handlePreview" v-model:file-list="formState.taxpayerImg"   :remove="handleRemove1" list-type="picture-card" :before-upload="beforeUpload">
                  <div v-if="formState.taxpayerImg.length < 1">
                    <plus-outlined></plus-outlined>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
            </div>
          </div>
          <div v-if="headerTypeId"> 
            <!-- 可多张 -->
            <span>开票资料上传（对方给的开票资料截图）</span>
            <div>
                <a-upload  @preview="handlePreview" v-model:file-list="formState.billingImg"   :remove="handleRemove2" list-type="picture-card" :before-upload="billingBeforeUpload">
                  <div>
                    <plus-outlined></plus-outlined>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
            </div>
          </div>
          <div  v-if="headerTypeId==3">
            <span>营业执照</span>
            <div>
                <a-upload  @preview="handlePreview" v-model:file-list="formState.businessLicenseImg"   :remove="handleRemove3" list-type="picture-card" :before-upload="businesBeforeUpload">
                  <div v-if="formState.businessLicenseImg.length<1" >
                    <plus-outlined></plus-outlined>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
            </div>
          </div>
          <div  v-if="headerTypeId==2">
            <span>组织机构代码证照片</span>
            <div>
                <a-upload  @preview="handlePreview" v-model:file-list="formState.institutionaImg"   :remove="handleRemove4" list-type="picture-card" :before-upload="institutionaBeforeUpload">
                  <div v-if="formState.institutionaImg.length < 1" >
                    <plus-outlined></plus-outlined>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
            </div>
          </div>
        </div>
      </template>
     </FormModal>
     <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
</div>
</template>

<script setup>
import { ref,onMounted,reactive } from 'vue';
import FormModal from '@/components/formModal/index.vue'
import { CloseOutlined ,plusOutlined} from "@ant-design/icons-vue";
import { fileUpload as upload ,invoiceAdd,invoiceList,invoiceEdit,invoiceDetail ,invoiceIsMain,invoiceEnableType} from '@/api/marketing/modList'
import { message } from 'ant-design-vue';
const props = defineProps({
 visible:{
  type:Boolean,
  default:false
 },
 customerId:{
  type:String,
  default:''
 }
})
const emit = defineEmits(['update:visible','listChange'])
const modalRef = ref(null)
const visibleImgPic = ref(false)
const headerTypeId = ref(null)
const fromList = ref([])
const formState= reactive({
      taxpayerImg:[],//纳税人资格照片
      taxpayerImgList: [],//纳税人资格照片
      billingImg:[],//开票资料截图
      billingImgList:[],//开票资料截图
      businessLicenseImg:[],//营业执照
      businessLicenseImgList:[],//营业执照
      institutionaImg:[],//组织结构代码照片
      institutionaImgList:[],//组织结构代码照片
    });
const previewVisible = ref(false)
const previewImage = ref('')
const getVal = (val) => {
  if( isUpdateLoading.value) return
  if(!headerTypeId.value){
    message.warn('请选择抬头类型')
    return
  }
  if(headerTypeId.value != 3){
    if(!val.name){
      message.warn('请输入名称')
      return
    }
    if(val.taxIdentNum&&val.taxIdentNum.length !== 18){
      if(headerTypeId.value == 1){
        message.warn('身份证号格式不正确')
      }else{
         message.warn('组织机构代码格式不正确')
      }
      return
    }
      Object.assign(val, getImgUpdate());
        reqInvoiceAdd(val)
  }else{
    modalRef.value.formRef.validate()
        .then(() => {
          Object.assign(val, getImgUpdate());
          reqInvoiceAdd(val)
        })
        .catch((error) => {
         message.warn('请完善表单内容')
        });
  }

}
const reqInvoiceAdd = (msg) => {
  msg.invoiceType = Number( headerTypeId.value)
  invoiceAdd(props.customerId,msg).then(res => {
    if(res.code !== 10000) return
    message.success(res.msg)
    emit('update:visible',false)
    emit('listChange')
  })
}


//预览图片
const lookImgID = ref([])
const lookImg = (type,item) => {
  let ids = item[type]
  if(typeof(ids) == 'string') {
    let id = []
    id.push(ids)
    lookImgID.value = id
  }else{
    lookImgID.value = ids
  }
 visibleImgPic.value = !visibleImgPic.value
}


// 获取上传信息照片
const getImgUpdate = () => {
  let ids =  []
  formState.billingImgList.forEach(item => {
    ids.push(item.id)
  })
  let licenseFileId = '' 
  if(formState.institutionaImgList.length>0){
    licenseFileId = formState.institutionaImgList[0].id
  }else if(formState.businessLicenseImgList.length>0){
    licenseFileId = formState.businessLicenseImgList[0].id
  }
  let arr = {
    taxpayerFileId:formState.taxpayerImgList.length>0?formState.taxpayerImgList[0].id:'', //一般纳税人资格照片
    screenshotIds:ids,//资料照片
    licenseFileId:licenseFileId,//组织结构照片||营业执照
  }
  return arr
}
//纳税人图片上传
 const beforeUpload = (file) => {
  updateBatch(file,1)
  return false
}
//开票资料照片
const billingBeforeUpload = (file) => {
  updateBatch(file,2)
  return false
}
// 营业资格照片
const businesBeforeUpload = (file) => {
  updateBatch(file,3)
  return false
}
// 组织结构照片
const institutionaBeforeUpload = (file) => {
  updateBatch(file,4)
  return false
}
const key = 'updatable';
const isUpdateLoading = ref(false)
// 图片上传type==1为纳税人照片、2为开票资料照片、3为营业资格照片、4为组织结构照片
const updateBatch = (file,type) => {
    isUpdateLoading.value = true
    message.loading({ content: '上传中..', key });
    const formData = new FormData()
    formData.append('file', file)
    upload(formData).then((res) => {
      isUpdateLoading.value = false
      if (res.code === 10000) {
        if(type == 1){
          formState.taxpayerImgList.push(res.data)
        }
        if(type == 2){
          formState.billingImgList.push(res.data)
        }
        if(type == 3){
          formState.businessLicenseImgList.push(res.data)
        }
        if(type == 4){
          formState.institutionaImgList.push(res.data)
        }
        message.success({ content: '上传成功', key, duration: 2 });
      }else{
        message.error({ content: '上传失败', key, duration: 2 });
      }
    })
}
//预览图片
const handlePreview =  async(file) => {
  if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj))
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
};
  // 图片移除
  const handleRemove1 = (file) => {
    if(file.originFileObj) {
      formState.taxpayerImgList.forEach((item, index) => {
        if (item.name === file.originFileObj.name) formState.taxpayerImgList.splice(index, 1)
      })
    }else{
      formState.taxpayerImgList.forEach((item, index) => {
        if (item.id === file.id) formState.taxpayerImgList.splice(index, 1)
      })
    }
      
  }
  const handleRemove2 = (file) => {
    if(file.originFileObj){
      formState.billingImgList.forEach((item, index) => {
        if (item.name === file.originFileObj.name) formState.billingImgList.splice(index, 1)
      })
    }else{
       formState.billingImgList.forEach((item, index) => {
        if (item.id === file.id) formState.billingImgList.splice(index, 1)
      })
    }
      
  }
  const handleRemove3 = (file) => {
    if(file.originFileObj) {
      formState.businessLicenseImgList.forEach((item, index) => {
        if (item.name === file.originFileObj.name) formState.businessLicenseImgList.splice(index, 1)
      })
    }else{
      formState.businessLicenseImgList.forEach((item, index) => {
        if (item.id === file.id) formState.businessLicenseImgList.splice(index, 1)
      })
    }
     
  }
  const handleRemove4 = (file) => {
    if(file.originFileObj) {
      formState.institutionaImgList.forEach((item, index) => {
        if (item.name === file.originFileObj.name) formState.institutionaImgList.splice(index, 1)
      })
    }else{
      formState.institutionaImgList.forEach((item, index) => {
        if (item.id === file.id) formState.institutionaImgList.splice(index, 1)
      })
    }
      
  }

//选择抬头类型
const headerTypeChange = (e) => {
  modalRef.value.formRef.clearValidate()
  for(let i in formState){
    formState[i] = []
  }
  if(e == 1) {
   fromList.value = [...personage.value ]
  }
  if(e == 2) {
  fromList.value = [...government.value ]
  }
  if(e == 3){
  fromList.value = [...enterprise.value ]  
  }
  fromList.value.forEach( item => {
    item.value = ''
   })
}

const newlyAdd = () => {
  isEdit.value = false
  headerTypeId.value = null
  fromList.value = []
  visible.value = !visible.value
}
const cancel = () => {
  for(let i in formState){
    formState[i] = []
  }
  emit('update:visible',false)
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const listData = ref([])
//企业 
const enterprise = ref([
  {
    label: "名称",
    name: "name",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "name",
    rules: [{ required: true, message: "请输入名称", trigger: "blur" }],
  },
  {
    label: "税号",
    name: "taxIdentNum",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "taxIdentNum",
    rules: [{ required: true, message: "请输入税号", trigger: "blur" },{ min: 18, max: 18, message: '税号格式不正确', trigger: 'blur' },],
  },
  {
    label: "电话",
    name: "phone",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "phone",
    rules: [{ required: true, message: "请输入电话", trigger: "blur" }],
  },
  {
    label: "地址",
    name: "address",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "address",
    rules: [{ required: true, message: "请输入地址", trigger: "blur" }],
  },
  {
    label: "开户行",
    name: "bank",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "bank",
    rules: [{ required: true, message: "请输入开户行", trigger: "blur" }],
  },
  {
    label: "银行账号",
    name: "account",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "account",
    rules: [{ required: true, message: "请输入银行账号", trigger: "blur" }],
  },
]
)
//个人
const personage = ref([
  {
    label: "名称",
    name: "name",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "name",
    rules: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "身份证号",
    name: "taxIdentNum",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "taxIdentNum",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "电话",
    name: "phone",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "phone",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "地址",
    name: "address",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "address",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "开户行",
    name: "bank",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "bank",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "银行账号",
    name: "account",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "account",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
])
//政府单位
const government = ref([
  {
    label: "名称",
    name: "name",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "name",
    rules: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "组织机构代码",
    name: "taxIdentNum",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "taxIdentNum",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "电话",
    name: "phone",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "phone",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "地址",
    name: "address",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "address",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "开户行",
    name: "bank",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "bank",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "银行账号",
    name: "account",
    type: "input",
    placeholder: "",
    value: null,
    width: "100%",
    prop: "account",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
])

</script>

<style lang="less" scoped>

</style>