<template>
  <div>
    <div>
      <SearchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
        <template #customFront>
          <a-select style="margin-right:10px" allowClear v-model:value="state.invoiceCarrier.value" :style="{ width: state.invoiceCarrier.width + 'px'}" :placeholder="state.invoiceCarrier.placeholder" @select="selectChnage">
            <a-select-option v-for="opt in state.invoiceCarrier.opt" :key="opt.id" :value="opt.id">{{ opt.subjectName }}</a-select-option>
          </a-select>
          <a-select style="margin-right:10px" allowClear v-model:value="state.taxItem.value" :style="{ width: state.taxItem.width + 'px'}" :placeholder="state.taxItem.placeholder" :disabled="state.invoiceCarrier.value?false:true">
            <a-select-option v-for="opt in state.taxItem.opt" :key="opt.id" :value="opt.id">{{ opt.label }}</a-select-option>
          </a-select>
        </template>
        <template #customFooter>
          <div class="flex">
            <a-input placeholder="开票金额始" style="width:185px" v-model:value="state.IceAmt.invoiceAmtStart"></a-input>
          <a-input placeholder="开票金额末" style="width:185px" v-model:value="state.IceAmt.invoiceAmtEnd"></a-input>
          </div>
        </template>
        <template #tail>
          <div style="margin-left: -10px;">
            <a-button class="m-l1" @click="newlyVisible = true" type="primary">+新增开票</a-button>
            <a-button class="m-l1" type="primary" @click="mergeAdd">合并开票</a-button>
            <a-button class="m-l1" @click="exportData" :disabled="exportDisabled" type="primary">导出明细</a-button>
          </div>
        </template>
      </SearchList>
    </div>
    <div style="width: 100%">
      <a-table :scroll="{ x:0,y:600  }" :loading="loading" @change="changePage" :pagination="pagination" bordered :row-selection="{ selectedRowKeys:state.selectedRowKeys, onChange: onSelectChange }" :rowKey="record => record.id" :columns="columns" :data-source="dataList">
        <template #purchasingUnit="{record}">
          {{ record.purchasingUnit }}
          <div v-if="record.invoiceTypeName">【{{ record.invoiceTypeName }}】</div>
        </template>
        <template #invoiceCarrier="{ record }">
          <div>
            <div>{{ record.invoiceCarrier }}</div>
            <div><span v-if="record.taxRate">{{ record.taxRate }}% - </span>{{ record.taxItem }}</div>
          </div>
        </template>
        <template #invoiceType="{ record }">
          {{ record.invoiceType?.label }}
          <div>{{  record.invoiceMethod?.label  }}</div>
        </template>
        <template #ticketCategory="{ record }">
          <div>{{  record.ticketCategory?.label }}</div>
          {{  record.invoiceMethod?.label }}
        </template>
        <template #applyInvoiceType="{ record }">
          {{ record.applyInvoiceType?.label }} <div v-if="record.cancelResult?.value > 2 "><a-tag color="red">{{record.cancelResult?.label}}</a-tag></div>

        </template>
        <template #invoiceResult="{ record }">
          {{  record.invoiceResult?.label}}
        </template>
        <template #add="{record}">
          <a @click="lookAdd(record)" class="m-r1">查看</a>
          <template v-if="record.invoiceResult?.value==1">
            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="confirm(record)">
              <template #title>
                您确定要删除吗？
              </template>
              <span class=" cur-p" style="color:red">删除</span>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </div>
    <NewlyInitiate v-if="newlyVisible" @submit="submit" :invoiceCarrierList="state.invoiceCarrier.opt" v-model:visible="newlyVisible"></NewlyInitiate>
    <div>
      <LookInitiate @handleOk="handleOk" :invoiceCarrierList="state.invoiceCarrier.opt" v-if="lookVisible" :lookID="lookID" v-model:visible="lookVisible"></LookInitiate>
      <LookEnd @redPush="submit" v-if="lookEndVisible" :lookID="lookID" :lookItem="lookItem" v-model:visible="lookEndVisible"></LookEnd>
      <div>
        <a-modal v-model:visible="mergeVisible" title="合并对账账单" width="95%" :maskClosable='false' @ok="mergeStart">
          <b>合并后金额：</b><span style="color: #F90909">￥{{ state.mergeAmt }}</span>
          <a-table :pagination="false" :dataSource="state.mergeList" :loading="loading" :rowKey="record => record.id" size="small" bordered :columns="mergeColumns">
            <template #invoiceCarrier="{ record }">
              <div>{{ record.invoiceCarrier }}</div>
              <div>{{ record.taxRate }} % - {{ record.taxItem }}</div>
            </template>
            <template #amt="{ record }">
               ￥{{ record.amt }} 
            </template>
          </a-table>
          <div style="width:100%;color:red" class="flex ju-end m-t2">请注意：合并后之前的对账单会合并成一个，客户名只会保留最后一个的，且只能合并你自己的对账单。</div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { billInvoiceExport } from '@/api/trans/finance/bill'
import SearchList from '@/components/searchList/index.vue'
import NewlyInitiate from './newlyInitiate/index.vue'
import LookInitiate from './lookInitiate/index.vue'
import LookEnd from './lookInitiate/lookEnd.vue'
import { billInvoicePage, InvoiceDelete, billInvoiceMerge} from '@/api/finance/finance.js'
import { getSubjectList } from '@/api/trans/order'
import { message } from 'ant-design-vue'
const state = reactive({
  selectedRowKeys: [],
  mergeList: [],
  mergeAmt: 0,
  invoiceCarrier: {
    value: null,
    width: 180,
    placeholder: '开票主体',
    opt: []
  },
  taxItem: {
    value: null,
    width: 180,
    placeholder: '开票税率',
    opt: []
  },
  IceAmt:{
    invoiceAmtStart:null,
    invoiceAmtEnd:null
  }
})
const loading = ref(false)
const newlyVisible = ref(false)
const lookVisible = ref(false)
const lookEndVisible = ref(false)
const exportDisabled = ref(false)
const mergeVisible = ref(false)
const dataList = ref([])
const columns = ref([
  {
    title: '发票号',
    dataIndex: 'invoiceNum',
    key: 'invoiceNum',
    align: 'center',
    width: '120px'
  },
  {
    title: '开票单号',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: '120px'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
    width: '150px'
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '购方单位',
    dataIndex: 'purchasingUnit',
    key: 'purchasingUnit',
    align: 'center',
    width: '200px',
    slots: { customRender: 'purchasingUnit' }
  },
  {
    title: '金额',
    dataIndex: 'amt',
    key: 'amt',
    align: 'center',
    width: '80px'
  },
  {
    title: '开票人',
    dataIndex: 'sponsor',
    key: 'sponsor',
    align: 'center',
    width: '80px'
  },
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    key: 'invoiceCarrier',
    align: 'center',
    width: '220px',
    slots: { customRender: 'invoiceCarrier' }
  },
  {
    title: '票类',
    dataIndex: 'ticketCategory',
    key: 'ticketCategory',
    align: 'center',
    slots: { customRender: 'ticketCategory' }
  },
  {
    title: '开票类型',
    dataIndex: 'applyInvoiceType',
    key: 'applyInvoiceType',
    align: 'center',
    slots: { customRender: 'applyInvoiceType' }

  },
  {
    title: '申请结果',
    dataIndex: 'invoiceResult',
    key: 'invoiceResult',
    slots: { customRender: 'invoiceResult' },
    align: 'center',
    width: '100px'
  },
  {
    title: '流程编号',
    dataIndex: 'flowInstanceId',
    key: 'flowInstanceId',
    align: 'center',
    width: '120px'
  },

  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: '100px',
    fixed: 'right',  
    slots: { customRender: 'add' }
  }])
const iptData = ref([
  {
    type: 'select',
    placeholder: '申请类型',
    value: null,
    prop: 'applyInvoiceType',
    width: 180,
    opt: [
      {
        name: '开票',
        id: 1
      },
      {
        name: '作废',
        id: 2
      },
      {
        name: '红冲',
        id: 3
      }
    ]
  },
  {
    type: 'select',
    placeholder: '申请结果',
    value: null,
    prop: 'invoiceResultType',
    opt: [
      {
        name: '新增',
        id: 1
      },
      {
        name: '申请中',
        id: 2
      },
      {
        name: '已完成',
        id: 9
      }
    ],
    width: 180
  },
  {
    type: 'input',
    placeholder: '发票号',
    value: null,
    prop: 'invoiceNum',
    width: 180
  },
  {
    type: 'input',
    placeholder: '企业抬头名称/采购单位',
    value: '',
    prop: 'purchasingUnit',
    width: 180
  },
  {
    type: 'input',
    placeholder: '流程编号',
    value: '',
    prop: 'flowInstanceId',
    width: 180
  },
  {
    type: 'input',
    placeholder: '开票单号',
    value: '',
    prop: 'id',
    width: 180
  },
  {
    type: 'input',
    placeholder: '客户名称',
    value: '',
    prop: 'customerName',
    width: 180
  }
])
const mergeColumns = ref([
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    align: 'center',
    slots:{ customRender:'invoiceCarrier' }
  },
  {
    title: '开票金额',
    dataIndex: 'amt',
    align: 'center',
    slots:{ customRender:'amt' }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
  },
])
const pagination = ref({
  showSizeChanger: true,
  showQuickJumper: true,
  current: 1,
  pageSize: 10,
  total: 0,
  showTotal: (totals) => `共 ${totals} 条`
})

//合并开票
const mergeStart = () => {
  let ids = []
  state.mergeList.forEach(item => {
    ids.push(item.id)
  })
  billInvoiceMerge({
        ids: ids
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          mergeVisible.value = false
          reqBillInvoicePage()
        }
      })
}
const mergeAdd = () => {
  state.mergeAmt = 0
  let list = state.mergeList
  if (list.length === 0) {
    message.error('请选择需要合并的账单')
    return
  }
  if(!list.every(item => item.invoiceResult?.value == 1)){
    message.error('合并时，状态只能是 新增对账，才可合并')
    return
  }
  if (!list.every(item => item.customerName == list[0].customerName)) {
    message.error('开票客户不一致，请从新选择')
    return
  }
  if (!list.every(item => item.invoiceCarrier == list[0].invoiceCarrier)) {
    message.error('开票主体不一致，请从新选择')
    return
  }
  if (!list.every(item => item.taxRate == list[0].taxRate)) {
    message.error('开票税率不一致，请从新选择')
    return
  }
  let amount = 0
  list.forEach( item => {
    amount += Number(item.amt)
  })
  state.mergeAmt = amount
  mergeVisible.value = true
}
// 导出明细
const exportData = () => {
  exportDisabled.value = true
  billInvoiceExport(searchMsg.value, '发票明细-财务').then(res => {
    setTimeout(function () {
      exportDisabled.value = false
    }, 10000)
  })
}
const changePage = (val) => {
  pagination.value = val
  reqBillInvoicePage()
}
const selectChnage = (item) => {
  reqGetSubjectList(item)
  state.taxItem.value = null
}

// 处理数据
const processingData = (data, id, label) => {
  let str = ''
  data.forEach(item => {
    if (item.id == id) {
      str = item[label]
    }
  })
  return str
}
// 重置
const resetBtn = () => {
  state.invoiceCarrier.value = null
  state.taxItem.value = null
  pagination.value.current = 1
  state.IceAmt.invoiceAmtEnd = null
  state.IceAmt.invoiceAmtStart = null
  searchMsg.value = {}
  reqBillInvoicePage()
}
// 搜索
const searchMsg = ref({})
const searchAdd = (val) => {
  const subject = {
    invoiceCarrier: processingData(state.invoiceCarrier.opt, state.invoiceCarrier.value, 'subjectName'),
    taxItem: processingData(state.taxItem.opt, state.taxItem.value, 'taxItem'),
    taxRate: processingData(state.taxItem.opt, state.taxItem.value, 'taxRate')
  }
  Object.assign(val, subject, state.IceAmt)
  searchMsg.value = val
  pagination.value.current = 1
  reqBillInvoicePage()
}
// 删除
const confirm = (item) => {
  InvoiceDelete(item.id).then(res => {
    if (res.code !== 10000) return
    message.success('删除成功')
    reqBillInvoicePage()
  })
}

const submit = () => {
  reqBillInvoicePage()
}
// 查看
const lookItem = ref({})
const lookID = ref('')
const lookAdd = (item) => {
  lookID.value = item.id
  if (item.invoiceResult.value == 1) {
    lookVisible.value = true
  } else {
    lookItem.value = item
    lookEndVisible.value = true
  }
}
const onSelectChange = (selectedRowKeys, selectedRows) => {
  state.selectedRowKeys = selectedRowKeys
  state.mergeList = selectedRows
}
const reqBillInvoicePage = () => {
  loading.value = true
  const page = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(page, searchMsg.value)
  billInvoicePage(page).then(res => {
    loading.value = false

    if (res.code !== 10000) return
    pagination.value.total = res.data.total
    dataList.value = res.data.records
  })
}
const handleOk = () => {
  reqBillInvoicePage()
}
// 获取主体列表
const reqGetSubjectList = (id, name) => {
  getSubjectList(id).then(res => {
    if (res.code !== 10000) return
    if (id == 0) {
      state.invoiceCarrier.opt = res.data
    } else {
      res.data.forEach(item => {
        item.label = item.subjectName + `【${item.taxItem}】`
      })
      state.taxItem.opt = res.data
    }
  })
}
onMounted(() => {
  reqBillInvoicePage()
  reqGetSubjectList(0)
})
</script>

<style lang="less" scoped>

:deep(.ant-table-tbody > tr > td){
  padding: 10px;

}
</style>
